import React from 'react'
import '../App.css';

function Header(storeData) { 
    return (
        <>
        {storeData.setStoreData.map((data) => (           
            <div className="header" key={data.id}>
                <p className="contact-width">Contact : {data.storephone}</p>
                <img src={data.logo} alt="logo" />
                <p className="hidden-xs contact-width"></p>
            </div>
            ))
        }        
        </>
    )
}

export default Header
