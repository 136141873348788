import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import './App.css';
import { API_PRODUCT_AND_STORE} from './Admin/api';
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import localhost_confic from './Files/localhost_config.json';

function App() {  
  const [storeData, setStoreData] = useState([]);
  const [productData, setProductData] = useState([]);  

  const storeDetailAPI = async () => {
    // return axios.get(STORE_DETAILS)
    // .then(resp => {
    //   function clbk(resp) {
    //     setStoreData(resp.data);
    //   }
    //   eval(resp.data)
    // })
    const resp = await axios.get(API_PRODUCT_AND_STORE);
    var respData = resp.data;
    // var storedData ={};
    // storedData = respData;                           
    // storeData.push(storedData);                    
    
    // setStoreData( [...storeData]);
    setStoreData(respData.store_master);  
    setProductData(respData.product_master);
    console.log(storeData); 
  }

  useEffect(() => {
    if(window.location.hostname != "localhost"){
      storeDetailAPI(); 
    }else{   
      setStoreData(localhost_confic.store_master);  
      setProductData(localhost_confic.product_master);
    }
  }, [])
  return (
    <div className='mainApp'>
      <HelmetProvider>
      {storeData.map((data) => ( 
        <Helmet key={data.id}>
          <meta charSet="utf-8" />
          <title>{data.storename}</title>
          <link rel="icon" type="image/png" href={data.faviicon} />
        </Helmet>
        ))
      }
      </HelmetProvider>
      <Header setStoreData={storeData}/>
      <Home setStoreData={storeData} setProductData={productData} />
      <Footer setStoreData={storeData}/>
    </div>
  );

}
export default App