import React from 'react'
import '../App.css';

function Footer(storeData) {
  return (    
    <>
    {storeData.setStoreData.map((data) => (           
      <div className='footer' key={data.id}>
        {data.footertext && <div>{data.footertext} </div> }
        {data.storename}. By <a href="http://iamretailer.com/" target="_blank">i.am.retailer</a>
      </div>
      ))
    }  
    </>
  )
}

export default Footer