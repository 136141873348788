import axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";
import {  API_CHECKOUT_SAVE } from '../Admin/api';
import { Table, Button, Form, Checkbox } from 'semantic-ui-react';
import validator from 'validator';
import '../App.css';

function Home(storeData) {

  const [customer_fname, setFirstName] = useState('')
  const [customer_lname, setLastName] = useState('')
  const [customer_email, setEmailAddress] = useState('');
  const [customer_phone, setContNo] = useState('');
  const [checked, setChecked] = useState(false);

  const [qty, setQty] = useState('');
  const [subtotal, setPrice] = useState(0);
  const [apiData, setAPIData] = useState([]);
  

  const [prodCount, setProdCount] = useState([0]);
  const [noOfItems, setNoOfItems] = useState([0]);
  const [totalAmount, setTotalAmount] = useState([0]);
  const [youSave, setYouSave] = useState([0]);

  const [loading, setLoading]  =  useState(false);
  const [popup, setPopup] = useState(false);
  const [staticText, setStaticText] = useState('');
  const initialStateErrors = {
    email:{required:false,validate:false},
    phone:{required:false},
    name:{required:false},
    term:{required:false}
  };
  const [errors, setErrors] = useState(initialStateErrors);
  const [alertClass, setAlertClass] = useState(false);
  const [saveAmount, setSaveAmount] = useState(false);
 
    const resetFilter =()=>{
      setFirstName('');
      setLastName('');
      setEmailAddress('');
      setContNo('');
      setChecked(false);
      setQty('');
      setAPIData([...apiData]);   
      const dataApi = [...apiData];
      for(var index = 0; index < dataApi.length; index++){    
        var value = 0;
        handleQuantity (index, value) 
      }
      
    }
  const sendData = async () => {
    setLoading(true);
    let errors =initialStateErrors; 
    let hassError = true;
    if(customer_fname === "" ){
      hassError = false;      
      errors.name.required = true;
    }

    if(customer_email === ""){
      hassError = false;      
      errors.email.required = true;
    }else if(!validator.isEmail(customer_email)){
      hassError = false;      
      errors.email.validate = true;
    } 

    if(customer_phone === ""){
      hassError = false;      
      errors.phone.required = true;
      setStaticText("Contact number is required");
    }else if(customer_phone.length < 7 || customer_phone.length > 20){
      hassError = false;      
      errors.phone.required = true; 
      setStaticText("Please enter the valid number");  
    }
    
    if(!checked){
      hassError = false;      
      errors.term.required =true;  
    }

    if(hassError){
      var newProduct =[...apiData];
      var newProductList =[];
      for(var i=0; i<newProduct.length; i++)
      {
        if(newProduct[i].qty> 0)
        {
          var newProd ={};
          newProd.product_name = newProduct[i].productname;
          newProd.price = newProduct[i].finalPrice;
          newProd.quantity = newProduct[i].qty;
          newProd.sku = newProduct[i].code;
          newProd.total = newProduct[i].subtotal;
          newProductList.push(newProd);
        }
      }
      if(newProductList.length>0)
      {
          const data = {         
            type:'order',
            customer_fname, 
            customer_lname, 
            customer_phone, 
            customer_email,
            product:newProductList 
        };
        const mycallback = JSON.stringify(data);
        await axios.post(API_CHECKOUT_SAVE , mycallback ) 
        .then(function (response) {
              console.log(response);
              setStaticText(response.data.message);
              setAlertClass(true);
          })
          .catch(function (error) {
              console.log(error);
              setStaticText(error.message);
              setAlertClass(false);
          });        
        setPopup(true);
        resetFilter();
        setLoading(false);
      }
      else{
        setStaticText("Please fill the quantity for needed products");        
        setAlertClass(false);
        setPopup(true);
        setLoading(false);
      }
    }
    else{     
      setLoading(false);  
    }
    setErrors(errors);

    }
  
  useEffect(() => {
    for(var i = 0; i < storeData.setProductData.length; i++){
      storeData.setProductData[i].finalPrice =0;
      if(storeData.setProductData[i].specialprice === '' || storeData.setProductData[i].specialprice === null || typeof storeData.setProductData[i].specialprice === 'undefined' || storeData.setProductData[i].specialprice === undefined){
        storeData.setProductData[i].finalPrice = storeData.setProductData[i].price;
      }else{
        storeData.setProductData[i].finalPrice = storeData.setProductData[i].specialprice;
      }
    }
    setAPIData([...storeData.setProductData]);
  }, [storeData.setProductData])  

  

  const handleQuantity = (index, value) => {
    const newItems = [...apiData];
    if(value > 0)
    {
      newItems[index].qty = value;
    }
    else{
      newItems[index].qty = 0;
    }
    const currQty = newItems[index].qty;
    setQty(newItems); 
    calculateTotal(index, currQty);
  };

  const calculateTotal = (index, value) => {
    const prodList = [...apiData];
    prodList[index].qty = value;
    const qty = prodList[index].qty; 
    const price = prodList[index].finalPrice;
    const subtotal =  parseInt(qty) *  parseInt(price); 
    prodList[index].subtotal = subtotal;   
   
    setPrice(subtotal); 
    var data = prodList[index];
    calculateOtherValues(data);    
  };
  
  const calculateOtherValues = (data) => {
    const dataRow = [...apiData];
    let qtyCount = 0;
    var noOfItems = [];
    var totalAmt = 0;
    var youSaveAmt = 0;
    var youSaveTotAmt = 0;

    if(dataRow.length > 0)
    {
      for (var i =0; i < dataRow.length; i++)
      {
        if(dataRow[i].qty >= 0)
        {
          const qty = dataRow[i].qty;
          qtyCount =parseInt(qtyCount) + parseInt(qty);
          setProdCount(qtyCount);

          if(dataRow[i].qty > 0)
          {
            noOfItems.push(dataRow[i]);
            setNoOfItems(noOfItems.length);
          }
          else
          {
            setNoOfItems(noOfItems.length);
          }

          totalAmt = parseInt(totalAmt) + parseInt(dataRow[i].subtotal);
          setTotalAmount(totalAmt);
          youSaveAmt = parseInt(qty) * (parseInt(dataRow[i].price) - parseInt(dataRow[i].finalPrice));
          youSaveTotAmt = youSaveAmt + youSaveTotAmt;
          setYouSave(youSaveTotAmt);
          if(youSaveTotAmt > 0){
            setSaveAmount(true)
          }
          else{
            setSaveAmount(false)
          }
        }
      }
    }
  }

  // Input error alert
  const handleChangeName = (event) =>{ 
    setFirstName(event.target.value);
    if (event.target.value !== ""){
      errors.name.required = false;
    }
    else{
      errors.name.required = true;
    }
    setErrors(errors);  
  };
  const handleChangeEmail = (event) =>{ 
    setEmailAddress(event.target.value);
    if (event.target.value !== ""){
      errors.email.required = false;
      if (validator.isEmail(event.target.value)) {
        errors.email.validate = false;
      } 
      else {
        errors.email.validate = true;
      }
    }
    else{
      errors.email.required = true;
      errors.email.validate = false;
    }    
    setErrors(errors);  
  };
  const handleChangePhone = (event) =>{ 
    setContNo(event.target.value);
    if (event.target.value !== ""){
      errors.phone.required = false;
    }
    else{
      errors.phone.required = true;
    }
    setErrors(errors);  
  };
  const handleChangeTerm =(event, data)=>{
    const value = data.checked;
    if(value){
      setChecked(true);
      errors.term.required = false;
    }
    else{
      setChecked(false);
      errors.term.required = true;
    }
    setErrors(errors);
  }
  
  // Restrict symbols in Qty box
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const closePopup = () =>{
    setPopup(false);
  }
  const focusNextRef = useFocusNext();
  function useFocusNext() {
    const controls = useRef([]);
  
    const handler = (event) => {
      if (event.keyCode === 13) {
        // Required if the controls can be reordered
        controls.current = controls.current
          .filter((control) => document.body.contains(control))
          .sort((a, b) =>
            a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING
              ? -1 : 1
          );
  
        const index = controls.current.indexOf(event.target);
        const next = controls.current[index + 1];
        next && next.focus();
  
        // IE 9, 10
        event.preventDefault();
      }
    };
    return useCallback((element) => {
      if (element && !controls.current.includes(element)) {
        controls.current.push(element);
        element.addEventListener('keydown', handler);
      }
    }, []);
  };
  return (
    <section>
      { popup ? (
        <div className="popup">
          <div className={`popup-inner ${alertClass ? 'success-popup' :'danger-popup'}`}>
              <div className="content">
                {staticText}
              </div>            
              <button className="close" onClick={closePopup}>×</button>
            </div>
        </div>
      ) : null}
      
      <div className="product">
        <div className="description">
          <div className="info-headertext ribbon">
          {storeData.setStoreData.map((data) => (  
            <strong className="ribbon-content" key={data.id} dangerouslySetInnerHTML={{ __html: data.bannertext }}></strong>
            ))
          }
          </div>
        </div>
        <div id="quick-shop">
          <div className={`grouped_total ${saveAmount ? 'save-total-in' :'save-total-not'}`}>
            <div className="nop totel-box hidden-xs">
              <span className="nop-name">NUMBER OF PRODUCTS</span>
              <span className="nop-bor"></span>
              <span className="nop-bor-bot"></span>
              <p className="totalitems">
                <span className="nop-value" id="no_of_prd"> {prodCount || 0} </span> </p>
            </div>
            <div className="noi totel-box">
              <span className="nop-name">NUMBER OF ITEMS</span>
              <span className="nop-bor"></span>
              <span className="nop-bor-bot"></span>
              <p className="totalitems">
                <span className="nop-value" id="no_of_item"> {noOfItems || 0} </span>
              </p> </div>
            <div className="ta totel-box">
              <span className="nop-name">TOTAL AMOUNT</span>
              <span className="nop-bor">
              </span>
              <span className="nop-bor-bot"></span>
              <p className="totalitems">
                <span className="nop-value" id="total_amount"> {totalAmount || 0} </span> </p>
            </div>
            {saveAmount ? (
              <div className="ys totel-box hidden-xs">
                <span className="nop-name">YOU SAVE</span>
                <span className="nop-bor"></span>
                <span className="nop-bor-bot"></span>
                <p className="totalitems">
                  <span className="nop-value" id="your_save"> {youSave || 0} </span>
                </p>
              </div>
              ):null
            }            
          </div>
          <div className="responsive-table">
            <div className="hidden-xs">
              <Table singleLine className="productList">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="center">Image</Table.HeaderCell>
                    <Table.HeaderCell className="left prod-name">Product Name</Table.HeaderCell>
                    <Table.HeaderCell className="right">Price</Table.HeaderCell>
                    <Table.HeaderCell className="center">Qty</Table.HeaderCell>
                    <Table.HeaderCell className="right">Sub Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    apiData.map((data, index) => (
                      <Table.Row key={data.id} id={index}>
                        <Table.Cell className="center"><img src={data.image} alt=""></img>
                        </Table.Cell>
                        <Table.Cell className="left prod-name">{data.productname}</Table.Cell>
                        {data.specialprice?(
                          <Table.Cell className="right prod-price"><p> &#8377; {data.specialprice}</p><span><strike> &#8377; {data.price}</strike></span></Table.Cell>
                          ):(
                          <Table.Cell className="right prod-price"><p> &#8377; {data.price}</p></Table.Cell>)
                        }                        
                        <Table.Cell className="center">
                          <div className="qtyCount">
                            {/* <Button className="decrement" onClick={() => handleQuantityDecrease(index, data.qty)}>-</Button> */}
                            <input inputMode="numeric" ref={focusNextRef} pattern="[0-9]*" type="number" placeholder="Qty" className="form-control" value={data.qty} onChange={(event) => handleQuantity(index, event.target.value)} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                            {/* <Button className="increment" onClick={() => handleQuantityIncrease(index, data.qty)}>+</Button> */}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="right"><input type="text" className="inputCtrl right" value={data.subtotal || 0} readOnly /></Table.Cell>
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
            </div>
          <div className="tableBody visible-xs">
            {
                apiData.map((data, index) => (
                  <div key={data.id} id={index} className="tableItem">
                    <div className="center imageSec">
                      <img src={data.image} alt=""></img>
                    </div>
                    <div className="left contentSec">
                      <div className="prod-name">{data.productname}</div>
                      <div className="prodRate">
                        {data.specialprice?(
                          <div className="prod-price"><p> &#8377; {data.specialprice}</p><span><strike> &#8377; {data.price}</strike></span></div>
                          ):(
                          <div className="prod-price"><p> &#8377; {data.price}</p></div>)
                        }
                        <div className="qtyCount"><input inputMode="numeric" ref={focusNextRef} placeholder="Qty" pattern="[0-9]*" type="number" className="form-control" value={data.qty} onChange={(event) => handleQuantity(index, event.target.value)} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}/></div>
                        <div className="subTotal"><span>&#8377;</span><span><input type="text" className="inputCtrl" value={data.subtotal || 0} readOnly /></span></div>
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>
          </div>
        </div>
        <div className="form-qr-section">
          <Form className="contactForm">
            <Form.Field className="form-group">
              <label>Name <span>*</span></label>
              <input type="text"value={customer_fname} className="inputCtrl" onChange={handleChangeName} required/>
              { errors.name.required?
                  (<span className="text-danger" >
                      Name is required.
                    </span>):null
              }
            </Form.Field>
            <Form.Field className="form-group">
              <label>Initial</label>
              <input type="text" value={customer_lname} className="inputCtrl" onChange={event => setLastName(event.target.value)} />
            </Form.Field>
            <Form.Field className="form-group">
              <label>Email Address <span>*</span></label>
              <input type="email" value={customer_email} className="inputCtrl" onChange={handleChangeEmail} required/>
              { errors.email.required?
                  (<span className="text-danger" >
                      Email is required.
                    </span>):null
              }
              { errors.email.validate?
                (<span className="text-danger" >
                    Please enter valid Email.
                  </span>):null
              }
            </Form.Field>
            <Form.Field className="form-group">
              <label>Contact Number <span>*</span></label>
              <input inputMode="numeric" pattern="[0-9]*" type="number" value={customer_phone} className="inputCtrl hideControls" onChange={handleChangePhone} required/>
              { errors.phone.required?
                  (<span className="text-danger" >
                      {staticText}
                    </span>):null
              }
            </Form.Field>
            <Form.Field>
              <Checkbox checked={checked} label="I have read and agree to the terms and conditions" onChange={handleChangeTerm} />
              { errors.term.required?
                  (<span className="text-danger" >
                    Please check the terms and condition.
                    </span>):null
              }
              {storeData.setStoreData.map((data) => (  
                <p key={data.id}>{data.storeterms }</p>
                ))
              }
            </Form.Field>
            <Form.Field className="right">
            {loading ?
              (<Button disabled={loading}  className="sendBtn button--loading"><span className="button__text">Send Enquiry</span></Button>)            
              :
              (<Button onClick={sendData} className="sendBtn">Send Enquiry</Button>)
            }
            
              
            </Form.Field>
          </Form>
          {
            storeData.setStoreData.map((data) => (           
              <div className="qrcode-section" key={data.id}>
                {data.paymentTerms ? (
                    <div className="term-section">
                      <h6>Payment Terms</h6>
                      <p dangerouslySetInnerHTML={{ __html: data.paymentTerms }}></p>
                    </div>
                  ):null
                }
                {data.paymentQR ? (
                  <div className="term-section qr-section">
                    <h6>Scan QR</h6>
                    <div className="img-box">
                    <img src={data.paymentQR} alt=""/>
                    </div>
                  </div>
                  ):null
                }
              </div>              
            ))
          }
        </div>
      </div>
    </section>)
}
export default Home